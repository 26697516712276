import React from 'react';
import Soap from '../../../media/steven-soaps/StevenSoaps.png';
import ProjectWithPictureMinimal from '../../../components/project-with-picture-minimal';

const ProjectEGG = () => (
	<ProjectWithPictureMinimal
		title={'Steven Soaps'}
		description1={
			'A website to showcase my soap creations! Made in TypeScript + Firebase.'
		}
		image1={Soap}
		image1Title={'Main Page'}
		description2={
			'While this website will eventually contain purchasing capabilities, ' +
			'for now it is just a simple showcase of some soaps I have made.'
		}
		link={'https://stevensoaps.com/'}
		linkDescription={'Link'}
	/>
);

export default ProjectEGG;
