import React from 'react';
import CenterSpace from '../../components/center-space';
import ButtonSection from '../../components/button-section';
import ConditionallyCenteredText from '../../components/conditionally-centered-text';
import Button from '@material-ui/core/Button';

const About = () => (
	<CenterSpace>
		<ConditionallyCenteredText title>
			{'More About Me:'}
		</ConditionallyCenteredText>
		<ConditionallyCenteredText body>
			{"Hi! I'm a software engineer from Trumbull, Connecticut, " +
				'located just an hour away from NYC. ' +
				'My professional experience includes a software engineering ' +
				'position at Flashpoint Intelligence, and a ' +
				'Software Engineering Internship at DealerPolicy, Inc. ' +
				'For more information: '}
			<Button
				component={'a'}
				target={'_blank'}
				rel={'noopener noreferrer'}
				href={'https://www.linkedin.com/in/cs-stevenbaumann/'}
			>
				{'Linkedin'}
			</Button>
		</ConditionallyCenteredText>
		<ButtonSection showHomeButton showProjectsButton />
	</CenterSpace>
);
export default About;
